import React, {useEffect, useState} from 'react';
import {get} from '../../common/requester';
import {botUsername} from "../../common/config";
import telegram from '../../images/telegram.webp';
import aygen from '../../images/aygen.jpg';
import './Anonymous.css'


const Anonymous = () => {
    const [langs, setLangs] = useState([])
    const [language, setLanguage] = useState('ru')
    const [welcome, setWelcome] = useState('')

    const getLanguages = async () => {
        const ls = await get('languages', {})
        if (ls) {
            setLangs(ls.languages)
        }
    }

    const getTranslation = async (word) => {
        const tr = await get('translations', {'language': language, 'word': word})
        if (tr) {
            setWelcome(tr.t)
        }
    }

    const onLanguageSelected = (lang) => {
        setLanguage(lang.id)
    }
    
    const getLanguageTitle = () => {
      const lng = langs.find(l => l.id === language)
      return lng ? `${lng.icon} ${lng.label}` : 'Русский'
    }

    useEffect(() => {
        getLanguages()
    }, [])

    useEffect(() => {
        getTranslation('welcome_anonymous')
    }, [language])

    return (
      <div className={"container"} style={{backgroundImage: `url(${aygen})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
          <div className={"center"}>
              <div className="switch-lang">
                  <div className="current-lang">
                      <p className="lang-text">{getLanguageTitle()}</p>
                  </div>
                  <div className="lang-dropdown">
                      {langs.map(l => (
                                <div className={"selecting-lang"} key={l.id} onClick={() => onLanguageSelected(l)}>
                                    <p className={"lang-text"}>{l.icon} {l.label}</p>
                                </div>
                        ))}
                  </div>
              </div>
              <div className={"welcome-text"}>
                  <p>{welcome}</p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
              </div>
              <div className={"telegram-link"}>
                  <a href={`https://t.me/${botUsername()}?start=/start`}><img src={telegram} style={{width: '50px'}}/></a>
              </div>
              </div>
      </div>
    );
};

export default Anonymous;