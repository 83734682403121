import React, {useEffect, useState} from 'react';
import {get} from "../../common/requester";
import {useTelegram} from '../../hooks/useTelegram'
import {AppRoot, Section, Cell} from '@telegram-apps/telegram-ui'

const VersionNotSupported = () => {
    const {tg} = useTelegram()
    const [lowerVersion, setLowerVersion] = useState('')

     const getTranslation = async (word) => {
        const tr = await get('translate', {'word': 'lower_version'})
        if(tr) {
            setLowerVersion(tr.t)
        }
    }

    useEffect(() => {
        getTranslation()
    }, [])

    return (
      <AppRoot>
        <Section header={"ToyPoy"} footer={tg.version}>
          <Cell multiline>
            {lowerVersion}
          </Cell>
        </Section>
      </AppRoot>
    );
};

export default VersionNotSupported;