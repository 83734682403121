import React from 'react';
import {AiFillSetting} from 'react-icons/ai';
import './Header.css'
import { Section, Cell, IconButton} from '@telegram-apps/telegram-ui'
import { useNavigate } from "react-router-dom";
import appStore from '../../storage/AppStore';
import {observer} from 'mobx-react-lite'

const Header = () => {
    const navigate = useNavigate();
    
    return (
      <Section>
          <Cell
            after={
                <IconButton size={"l"} mode={"plain"} onClick={() => navigate('/settings')}>
                    <AiFillSetting size={24}/>
                </IconButton>
            }
          >
              {appStore.user.fio}
          </Cell>
      </Section>
    )
};

export default observer(Header);