import {action, autorun, computed, makeObservable, observable} from 'mobx'
import User from '../models/User'

class AppStore {
  jwt = '';
  user = new User();
  languages = []
  user_types = []
  tr = {}
  
  constructor() {
    makeObservable(this, {
      jwt: observable,
      user: observable,
      languages: observable,
      user_types: observable,
      tr: observable,
      setJwt: action,
      initUser: action,
      setLanguages: action,
      setTranslations: action,
      setUserTypes: action
    });
    autorun(() => console.log('--appStore autorun'));
  }
  
  setJwt(token) {
    this.jwt = token
  }
  
  initUser(json) {
    this.user.fromJson(json)
  }
  
  setLanguages(data) {
    this.languages = data
  }
  
  setTranslations(data) {
    this.tr = data
  }
  
  setUserTypes(data) {
    this.user_types = data
  }
}
const appStore = new AppStore();
export default appStore;