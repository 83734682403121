import { useTelegram} from '../hooks/useTelegram'
import {getBaseUrl} from "./config";

const {jwt, tg, isValid} = useTelegram()

const handleMessage = (msg) => {
    if(isValid()) {
        tg.showPopup(msg)
    } else {
        alert(msg)
    }
    return null
}

export const get = async (endpoint, data) => {
    const url = `${getBaseUrl()}/${endpoint}`;
    const options = {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': `Bearer ${jwt}`
        },
        body: JSON.stringify(data)
    }
    try {
        let response = await fetch(url, options)
        if (response.ok) {
            let json =  await response.json();
            if(json.result !== 0) {
                return handleMessage(json.message)
            }
            return json;
        } else {
            return handleMessage('error')
        }
    } catch(err) {
        return handleMessage(err)
    }


}