import {useEffect, useState} from "react";
import {useTelegram} from "./hooks/useTelegram";
import {get} from './common/requester'
import Header from "./components/Header/Header";
import {Route, Routes} from 'react-router-dom';
import Anonymous from "./components/Anonymous/Anonymous";
import VersionNotSupported from "./components/VersionNotSupported/VersionNotSupported";
import Supplier from './components/Supplier/Supplier'
import Client from './components/Client/Client'
import '@telegram-apps/telegram-ui/dist/styles.css'
import {AppRoot, Section, List, Cell} from '@telegram-apps/telegram-ui'
import Companies from './components/Companies/Companies'
import AddService from './components/Services/AddService'
import EditService from './components/Services/EditService'
import Services from "./components/Services/Services";
import ViewService from "./components/Services/ViewService";
import Settings from './components/Settings/Settings'
import {observer} from 'mobx-react-lite'
import appStore from './storage/AppStore'

function App() {
    const {tg, isValid, isVersionValid, jwt} = useTelegram();
    
    const getTranslation = async (word) => {
        const tr = await get('translations', {'language': appStore.user.language, 'word': word})
        if (tr) {
            appStore.setTranslations(tr.t)
        }
    }
    
    const resetLastCommand = async () => {
        await get('last_command', {})
    }
    
    const getUser = async () => {
        if(isValid() && isVersionValid() && jwt) {
            const res = await get('user', {})
            if(res) {
                appStore.initUser(res)
                getTranslation()
                resetLastCommand()
            }
        }
    }

    useEffect(() => {
        tg.ready();
        tg.BackButton.show();
        tg.BackButton.onClick(() => {
            if (window.history.state.idx === 0) {
                tg.close()
            } else {
                window.history.back()
            }
        })
        getUser()
    }, [])
    

    if (!isValid()) return <Anonymous/>
    if (!isVersionValid()) return <VersionNotSupported/>
    return (
        <AppRoot>
            <Header/>
            {appStore.user.user_type === 's' &&
              <Routes>
                  <Route index element={<Supplier/>}/>
                  <Route path={'settings'} element={<Settings/>}/>
                  <Route path={'companies'} element={<Companies/>}/>
                  <Route path={'service/add'} element={<AddService/>}/>
                  <Route path={'service/edit/:id'} element={<EditService/>}/>
                  <Route path={'service/view/:id'} element={<ViewService/>}/>
                  <Route path={'services'} element={<Services />}/>
              </Routes>
            }
            {appStore.user.user_type === 'c' &&
              <Routes>
                  <Route index element={<Client/>}/>
                  {/*<Route path={'form'} element={<Form/>}/>*/}
              </Routes>
            }
        </AppRoot>
    );
}

export default observer(App);
