import React from 'react';
import {InlineButtons, List} from '@telegram-apps/telegram-ui'
import {AiFillDollarCircle, AiFillHome, AiFillPlusCircle} from 'react-icons/ai';
import { useNavigate } from "react-router-dom";
import appStore from '../../storage/AppStore'
import {observer} from 'mobx-react-lite'

const Supplier = () => {
    const navigate = useNavigate();
  
  return (
    <List style={{
        background: 'var(--tgui--secondary_bg_color)'
      }}>
    <InlineButtons mode="plain">
      <InlineButtons.Item text={appStore.tr.add} onClick={() => navigate('/service/add')}>
        <AiFillPlusCircle size={24}/>
      </InlineButtons.Item>
      <InlineButtons.Item text={appStore.tr.my_services}  onClick={() => navigate('/services')}>
        <AiFillDollarCircle size={24}/>
      </InlineButtons.Item>
      <InlineButtons.Item text={appStore.tr.my_companies} onClick={() => navigate('/companies')}>
        <AiFillHome size={24}/>
      </InlineButtons.Item>
    </InlineButtons></List>
  );
};

export default observer(Supplier);