import {isObservableArray} from 'mobx';

export default class BaseModel {
  store;

  constructor(store) {
    if (!store) {
      this.store = store;
    }
  }

  toJson() {
    const data = {};
    Object.keys(this).forEach(k => {
      const item = this[k];
      if (k !== 'store') {
        if (isObservableArray(item)) data[k] = item.splice();
        else if (item instanceof BaseModel) {
          data[k] = item.toJson();
        } else {
          data[k] = item;
        }
      }
    });
    return data;
  }

  fromJson(data) {
    if (!data) return;
    Object.keys(data).forEach(k => {
      if (k !== 'store' && this.hasOwnProperty(k)) {
        this[k] = data[k];
      }
    });
  }
}
