import React from 'react';

const Client = () => {
  return (
    <div>
      This is client
    </div>
  );
};

export default Client;