import {action, computed, makeObservable, observable} from 'mobx';
import BaseModel from './BaseModel';

export default class Service extends BaseModel {

   id = 0;
  name = '';
  company_id = '';
  company = {};
  country_id = '';
  country = {};
  region_id = '';
  region = {};
  district_id = '';
  district = {};
  currency = {};
  price = 0;
  service_type_id = '';
  service_type = {};
  service_entity_id = '';
  service_entity = {};
  entities = '';
  address = '';
  image = '';
  included = [];
  data = {};
  constructor(store) {
    super(store);
  
    makeObservable(this, {
      id: observable,
      name: observable,
      country_id: observable,
      region_id: observable,
      district_id: observable,
      currency: observable,
      price: observable,
      service_type_id: observable,
      service_entity_id: observable,
      included: observable,
      address: observable,
      image: observable,
      data: observable
    });
  }

  fromJson(data) {
    super.fromJson(data);
  }
}
