import React, {useEffect, useState} from 'react';
import Service from './Service'
import Supplier from "../Supplier/Supplier";
import appStore from '../../storage/AppStore'
import serviceStore from '../../storage/ServiceStore'

const AddService = () => {
  
  useEffect(() => {
    if(appStore.user) {
      const s = {
        id: 0,
        name: '',
        country_id: appStore.user.country_id,
        region_id: appStore.user.region_id,
        district_id: appStore.user.district_id,
        currency: appStore.user.country?.currency,
        price: 0
      }
      serviceStore.setService(s)
    }
  }, [appStore.user])
  
  return (
      <>
        <Supplier/>
        <Service/>
      </>
  );
};

export default AddService;