import React from 'react';
import { useParams } from 'react-router';
import Supplier from "../Supplier/Supplier";
import {Section, Cell} from '@telegram-apps/telegram-ui';
import {observer} from 'mobx-react-lite'


const EditService = () => {
    const params = useParams()
    return (
        <>
            <Supplier/>
            <Section>
                <Cell>Service id {params.id}</Cell>
            </Section>
        </>
    );
};

export default observer(EditService);