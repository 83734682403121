import appStore from '../storage/AppStore'

const tg = window.Telegram.WebApp;

function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

const getJwt = () => {
    if(!appStore.jwt) {
        const _jwt = getParameterByName('jwt')
        appStore.setJwt(_jwt)
    }
    return appStore.jwt;
}

export const isValid = () => {
    const t_id = tg?.initDataUnsafe?.user?.id
    return !!t_id;
}

export const isVersionValid = () => {
    const ver = tg?.version
    return ver && ver >= 6.9
}


export function useTelegram() {
    const onClose = () => {
        tg.close()
    }
    const onToggleButton = () => {
        if(tg.MainButton.isVisible) {
            tg.MainButton.hide()
        } else {
            tg.MainButton.show()
        }
    }
    return {
        onClose,
        onToggleButton,
        tg,
        user: tg.initDataUnsafe?.user,
        queryId: tg.initDataUnsafe?.query_id,
        jwt: getJwt(),
        isValid,
        isVersionValid,
    }
}