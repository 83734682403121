import {action, autorun, computed, makeObservable, observable, reaction} from 'mobx'
import Service from '../models/Service'
import {get} from '../common/requester'
import appStore from './AppStore'

class ServiceStore {
  service = new Service();
  service_types = [];
  service_entities = [];
  included_options = [];
  companies = [];
  services = [];
  
  constructor() {
    makeObservable(this, {
      service: observable,
      service_types: observable,
      service_entities: observable,
      included_options: observable,
      companies: observable,
      services: observable,
      resetService: action,
      getServices: action,
      setServices: action,
      setService: action,
      getCompanies: action,
      setCompanies: action,
      setServiceTypes: action,
      setServiceEntities: action,
      setIncludedOptions: action,
      getServiceTypes: action,
      getServiceEntities: action,
      setName: action,
      setCompany:action,
      setServiceType: action,
      setServiceEntity: action,
      setIncluded: action,
      setPrice: action,
      isValid: computed,
      addService: action,
      uploadImages: action
    });
    autorun(() => {
      if(appStore.user && appStore.user.user_type === 's') {
        console.log('--user exists. getting companies')
        this.getCompanies()
        this.getServiceTypes()
      }
    });
  }
  
  resetService() {
    this.service = new Service();
  }
  
  async getServiceById(_id) {
    const s = await get('service', {service_id: _id})
    if (s) {
      this.setService(s)
    }
  }
  
  async getServices() {
    const ss = await get('my_services', {})
    if (ss) {
      this.setServices(ss.services)
    }
  }
  
  setServices(data) {
    this.services = data
  }
  
  
  async getServiceTypes() {
    const st = await get('service_types', {})
    if (st) {
      this.setServiceTypes(st.service_types)
      if (st.service_types.length > 0 && !this.service.service_type_id) {
        this.setServiceType(st.service_types[0].id)
      }
    }
  }
  
  async getServiceEntities() {
    const st = await get('service_entities', {service_type_id: this.service.service_type_id})
    if (st) {
      this.setServiceEntities(st.service_entities)
      const se = this.service_entities.find(se => se.id === this.service.service_entity_id)
      if (!se || !this.service.service_entity_id) {
        this.setServiceEntity(st.service_entities[0].id)
      }
    }
  }
  
  setService(json) {
    this.resetService();
    this.service.fromJson(json)
  }
  
  async getCompanies() {
    const cc = await get('companies', {})
    if(cc) {
      this.setCompanies(cc.companies)
      if(!this.service.company_id) {
        if(this.companies.length > 0) {
          this.setCompany(cc.companies[0].id)
        }
      }
    }
  }
  
  
  setCompanies(data) {
    this.companies = data
  }
  
  setServiceTypes(data) {
    this.service_types = data
  }
  
  setServiceEntities(data) {
    this.service_entities = data
  }
  
  setIncludedOptions(data) {
    this.included_options = data
  }
  
  setName(nm) {
    this.service.name = nm
  }
  
  setCompany(_id) {
    this.service.company_id = _id
  }
  
  setServiceType(_id) {
    this.service.service_type_id = _id
    this.getServiceEntities()
  }
  
  setServiceEntity(_id) {
    this.service.service_entity_id = _id
    const se = this.service_entities.find(se => se.id === _id)
    this.setIncludedOptions(se.included)
    
    let incr = 0
    this.service.included.map(i => {
      if(this.included_options.includes(i)) {
        incr += 1
      }
    })
    if(incr !== this.service.included.length) {
      this.setIncluded([])
    }
  }
  
  setIncluded(data) {
    this.service.included = data
  }
  
  setPrice(pr) {
    this.service.price = pr
  }
  
  get isValid() {
    return this.service.name && this.service.company_id && this.service.service_type_id
      && this.service.service_entity_id && this.service.included && this.service.price
  }
  
  async addService() {
    const s = {...this.service}
    s['entities'] = this.service.included.map(i => {
      return i.value
    })
  
    const se = await get('edit_service', s)
    if (se) {
      return Promise.resolve(se)
    } else {
      return Promise.reject('error')
    }
  }
  
  
  async uploadImages() {
    const ui = get('last_command', {id: 'upload_service_images', db: this.service.id, continue_on_web: true})
    if(ui) {
      return Promise.resolve()
    } else {
      return Promise.reject()
    }
  }
}
const serviceStore = new ServiceStore();
export default serviceStore;