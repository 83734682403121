import {action, computed, makeObservable, observable} from 'mobx';
import BaseModel from './BaseModel';

export default class User extends BaseModel {

  constructor(store) {
    super(store);
    
     makeObservable(this, {
       id: observable,
       telegram_id: observable,
       fio: observable,
       first_name: observable,
       last_name: observable,
       username: observable,
       user_type: observable,
       language: observable,
       country_id: observable,
       region_id: observable,
       district_id: observable,
       fromJson: action
     });
  }

  id = 0;
  telegram_id = '';
  fio = '';
  first_name = '';
  last_name = '';
  username = '';
  user_type = 'c';
  language = 'ru';
  country_id = '';
  region_id = '';
  district_id = '';
  country = {};
  region = {};
  district = {}
 
  

  fromJson(data) {
    super.fromJson(data);
  }
}
